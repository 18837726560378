import React, { useState, useEffect } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import './App.css';
import PageLayoutDiagram from './components/PageLayoutDiagram';
import Tutorial from './components/Tutorial';
import ProcessingPanel from './components/ProcessingPanel';
import VersionIndicator from './components/VersionIndicator';

// Constants for standard paper sizes with dimensions in PDF points
const PAPER_SIZES = {
  LETTER: { width: 792, height: 612, name: 'Letter (8.5" × 11")' },
  LEGAL: { width: 1008, height: 612, name: 'Legal (8.5" × 14")' },
  TABLOID: { width: 1224, height: 792, name: 'Tabloid (11" × 17")' },
  A4: { width: 842, height: 595, name: 'A4 (210mm × 297mm)' },
  A3: { width: 1191, height: 842, name: 'A3 (297mm × 420mm)' },
  A5: { width: 595, height: 420, name: 'A5 (148mm × 210mm)' },
  A10: { width: 148, height: 105, name: 'A10 (26mm × 37mm)' },
  CUSTOM: { width: -11, height: 0, name: 'Custom Size' },
};

// Default application settings
const DEFAULT_SETTINGS = {
  sheetsPerSignature: 4,
  fillLastSignature: true,
  startPadding: 0,
  endPadding: 0,
  showHoleGuides: false,
  numberOfHoles: 5,
  showFoldLine: false,
  showPageNumbers: false,
  outputPaperSize: 'LETTER'
};

/**
 * Main application component that handles PDF processing and user interface
 * @component
 */
function App() {
  // State management for PDF processing
  const [pdfFile, setPdfFile] = useState(null);
  const [sheetsPerSignature, setSheetsPerSignature] = useState(() => {
    return parseInt(localStorage.getItem('sheetsPerSignature')) || DEFAULT_SETTINGS.sheetsPerSignature;
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [showPageNumbers, setShowPageNumbers] = useState(() => {
    return localStorage.getItem('showPageNumbers') === 'true';
  });
  const [fillLastSignature, setFillLastSignature] = useState(() => {
    return localStorage.getItem('fillLastSignature') !== 'false';
  });
  const [isDragging, setIsDragging] = useState(false);
  const [showHoleGuides, setShowHoleGuides] = useState(() => {
    return localStorage.getItem('showHoleGuides') === 'true';
  });
  const [numberOfHoles, setNumberOfHoles] = useState(() => {
    return parseInt(localStorage.getItem('numberOfHoles')) || DEFAULT_SETTINGS.numberOfHoles;
  });
  const [showFoldLine, setShowFoldLine] = useState(() => {
    return localStorage.getItem('showFoldLine') === 'true';
  });
  const [startPadding, setStartPadding] = useState(() => {
    return parseInt(localStorage.getItem('startPadding')) || DEFAULT_SETTINGS.startPadding;
  });
  const [endPadding, setEndPadding] = useState(() => {
    return parseInt(localStorage.getItem('endPadding')) || DEFAULT_SETTINGS.endPadding;
  });
  const [sourcePageCount, setSourcePageCount] = useState(null);
  const [currentSignatures, setCurrentSignatures] = useState(null);
  const [pageOrientation, setPageOrientation] = useState('portrait');
  const [showTutorial, setShowTutorial] = useState(() => {
    return localStorage.getItem('tutorialComplete') !== 'true';
  });
  const [showPostUploadTutorial, setShowPostUploadTutorial] = useState(false);
  const [processedPdfBlob, setProcessedPdfBlob] = useState(null);
  const [processedFilename, setProcessedFilename] = useState('');
  const [outputPaperSize, setOutputPaperSize] = useState(() => {
    return localStorage.getItem('outputPaperSize') || DEFAULT_SETTINGS.outputPaperSize;
  });
  const [customPaperWidth, setCustomPaperWidth] = useState(() => {
    return parseFloat(localStorage.getItem('customPaperWidth')) || 8.5;
  });
  const [customPaperHeight, setCustomPaperHeight] = useState(() => {
    return parseFloat(localStorage.getItem('customPaperHeight')) || 11;
  });
  const [useMillimeters, setUseMillimeters] = useState(() => {
    return localStorage.getItem('useMillimeters') === 'true';
  });
  const [panelStates, setPanelStates] = useState(() => ({
    pageLayout: false,
    paperSize: false,
    bookbinding: false,
    debug: false
  }));

  useEffect(() => {
    if (sourcePageCount !== null && !localStorage.getItem('postUploadTutorialComplete')) {
      if (localStorage.getItem('tutorialComplete') === 'true') {
        setShowPostUploadTutorial(true);
      }
    }
  }, [sourcePageCount]);

  useEffect(() => {
    localStorage.setItem('sheetsPerSignature', sheetsPerSignature);
  }, [sheetsPerSignature]);

  useEffect(() => {
    localStorage.setItem('fillLastSignature', fillLastSignature);
  }, [fillLastSignature]);

  useEffect(() => {
    localStorage.setItem('showHoleGuides', showHoleGuides);
  }, [showHoleGuides]);

  useEffect(() => {
    localStorage.setItem('numberOfHoles', numberOfHoles);
  }, [numberOfHoles]);

  useEffect(() => {
    localStorage.setItem('showFoldLine', showFoldLine);
  }, [showFoldLine]);

  useEffect(() => {
    localStorage.setItem('startPadding', startPadding);
  }, [startPadding]);

  useEffect(() => {
    localStorage.setItem('endPadding', endPadding);
  }, [endPadding]);

  useEffect(() => {
    localStorage.setItem('showPageNumbers', showPageNumbers);
  }, [showPageNumbers]);

  useEffect(() => {
    localStorage.setItem('outputPaperSize', outputPaperSize);
  }, [outputPaperSize]);

  useEffect(() => {
    localStorage.setItem('customPaperWidth', customPaperWidth);
  }, [customPaperWidth]);

  useEffect(() => {
    localStorage.setItem('customPaperHeight', customPaperHeight);
  }, [customPaperHeight]);

  useEffect(() => {
    localStorage.setItem('useMillimeters', useMillimeters);
  }, [useMillimeters]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const loadPdfDetails = async (file) => {
    try {
      const fileBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(fileBuffer, { ignoreEncryption: true });
      
      // Check orientation of all pages
      const orientations = pdfDoc.getPages().map(page => {
        const { width, height } = page.getSize();
        return width > height ? 'landscape' : 'portrait';
      });
      
      // Count occurrences of each orientation
      const orientationCounts = orientations.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {});
      
      // Use the most common orientation
      const dominantOrientation = Object.entries(orientationCounts)
        .reduce((a, b) => (a[1] > b[1] ? a : b))[0];
      
      setPageOrientation(dominantOrientation);
      setSourcePageCount(pdfDoc.getPageCount());
    } catch (err) {
      setError('Error reading PDF: ' + err.message);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const file = e.dataTransfer.files[0];
    if (!file) return;

    // Clear existing states first
    setSourcePageCount(null);
    setCurrentSignatures(null);
    setPdfFile(null);
    setError(null);

    if (file.type === 'application/pdf') {
      setPdfFile(file);
      setError(null);
      await loadPdfDetails(file);
    } else {
      setError('Please drop a valid PDF file');
    }
  };

  /**
   * Calculates the page order for signatures in the booklet
   * @param {number} totalPages - Total number of pages in the document
   * @param {number} sheetsPerSignature - Number of sheets per signature
   * @param {boolean} fillLast - Whether to fill the last signature
   * @returns {Array} Array of signature arrangements
   */
  const calculateSignaturePageOrder = (totalPages, sheetsPerSignature, fillLast = true) => {
    const signatures = [];
    let currentPage = 1;
    
    while (currentPage <= totalPages) {
      const signature = [];
      const pagesPerSignature = sheetsPerSignature * 4;
      let sigPages = Math.min(pagesPerSignature, totalPages - currentPage + 1);
      
      if (fillLast && currentPage + sigPages > totalPages) {
        sigPages = pagesPerSignature;
      } else {
        sigPages = Math.ceil(sigPages / 4) * 4;
      }
      
      const sheets = Math.ceil(sigPages / 4);
      for (let sheet = 0; sheet < sheets; sheet++) {
        const frontRight = currentPage + sigPages - sheet * 2 - 1;
        const frontLeft = currentPage + sheet * 2;
        const backLeft = currentPage + sheet * 2 + 1;
        const backRight = currentPage + sigPages - sheet * 2 - 2;
        
        signature.push([
          [
            frontRight > totalPages ? 0 : frontRight,
            frontLeft > totalPages ? 0 : frontLeft
          ],
          [
            backLeft > totalPages ? 0 : backLeft,
            backRight > totalPages ? 0 : backRight
          ]
        ]);
      }
      
      signatures.push(signature);
      currentPage += sigPages;
    }
    
    return signatures;
  };

  const processPDF = async (file) => {
    try {
      setIsProcessing(true);
      setError(null);
      setProcessedPdfBlob(null);

      const fileBuffer = await file.arrayBuffer();
      const sourcePdfDoc = await PDFDocument.load(fileBuffer, { ignoreEncryption: true });
      const sourcePages = sourcePdfDoc.getPageCount();
      setSourcePageCount(sourcePages);
      
      const paddedPageCount = sourcePages + startPadding + endPadding;
      
      const pagesPerSignature = sheetsPerSignature * 4;
      const requiredPages = fillLastSignature 
        ? Math.ceil(paddedPageCount / pagesPerSignature) * pagesPerSignature
        : paddedPageCount;
      const actualTotalPages = requiredPages;
      
      const signatures = calculateSignaturePageOrder(actualTotalPages, sheetsPerSignature, fillLastSignature);

      const finalDoc = await PDFDocument.create();
      
      const helveticaFont = await finalDoc.embedFont('Helvetica-Bold');

      const addHoleGuides = (page) => {
        const { width, height } = page.getSize();
        const centerX = width / 2;
        
        if (showFoldLine) {
          const lineSegments = 50;
          const segmentHeight = height / lineSegments;
          
          for (let i = 0; i < lineSegments; i += 2) {
            const yPosition = i * segmentHeight;
            
            page.drawLine({
              start: { x: centerX, y: yPosition },
              end: { x: centerX, y: yPosition + segmentHeight },
              thickness: 0.5,
              opacity: 0.3,
              color: rgb(0, 0, 0),
            });
          }
        }
        
        if (showHoleGuides) {
          const totalLength = height * 0.8;
          const margin = height * 0.1;
          const spacing = totalLength / (numberOfHoles - 1);
          
          for (let i = 0; i < numberOfHoles; i++) {
            const yPosition = margin + (i * spacing);
            
            page.drawEllipse({
              x: centerX,
              y: yPosition,
              xScale: 1,
              yScale: 1,
              color: rgb(0, 0, 0),
              opacity: 0.3,
              borderWidth: 0.5,
            });
          }
        }
      };

      const paperSize = outputPaperSize === 'CUSTOM' 
        ? {
            width: Math.round(customPaperWidth * 72), // Convert inches to points
            height: Math.round(customPaperHeight * 72)
          }
        : PAPER_SIZES[outputPaperSize];

      for (const signature of signatures) {
        for (const sheet of signature) {
          const [[frontRight, frontLeft], [backLeft, backRight]] = sheet;
          
          const frontPage = finalDoc.addPage([paperSize.width, paperSize.height]);
          const { width, height } = frontPage.getSize();
          
          if (frontRight > 0 && frontRight <= actualTotalPages) {
            const sourcePageIndex = frontRight - startPadding - 1;
            if (sourcePageIndex >= 0 && sourcePageIndex < sourcePages) {
              const embedFrontRight = await finalDoc.embedPage(sourcePdfDoc.getPage(sourcePageIndex));
              await frontPage.drawPage(embedFrontRight, {
                x: 0,
                y: 0,
                width: width / 2,
                height: height,
              });
            }
          }
          if (showPageNumbers) {
            frontPage.drawText(frontRight.toString(), {
              x: width/4,
              y: height/2,
              size: 60,
              font: helveticaFont,
              color: rgb(1, 0, 0),
              opacity: 0.5,
            });
          }
          
          if (frontLeft > 0 && frontLeft <= actualTotalPages) {
            const sourcePageIndex = frontLeft - startPadding - 1;
            if (sourcePageIndex >= 0 && sourcePageIndex < sourcePages) {
              const embedFrontLeft = await finalDoc.embedPage(sourcePdfDoc.getPage(sourcePageIndex));
              await frontPage.drawPage(embedFrontLeft, {
                x: width / 2,
                y: 0,
                width: width / 2,
                height: height,
              });
            }
          }
          if (showPageNumbers) {
            frontPage.drawText(frontLeft.toString(), {
              x: 3*width/4,
              y: height/2,
              size: 60,
              font: helveticaFont,
              color: rgb(1, 0, 0),
              opacity: 0.5,
            });
          }

          if (showHoleGuides || showFoldLine) {
            addHoleGuides(frontPage);
          }

          const backPage = finalDoc.addPage([paperSize.width, paperSize.height]);
          
          if (backLeft > 0 && backLeft <= actualTotalPages) {
            const sourcePageIndex = backLeft - startPadding - 1;
            if (sourcePageIndex >= 0 && sourcePageIndex < sourcePages) {
              const embedBackLeft = await finalDoc.embedPage(sourcePdfDoc.getPage(sourcePageIndex));
              await backPage.drawPage(embedBackLeft, {
                x: 0,
                y: 0,
                width: width / 2,
                height: height,
              });
            }
          }
          // Draw back left page number
          if (showPageNumbers) {
            backPage.drawText(backLeft.toString(), {
              x: width/4,  // Position on left side
              y: height/2,
              size: 60,
              font: helveticaFont,
              color: rgb(1, 0, 0),
              opacity: 0.5,
            });
          }
          
          // Handle back RIGHT page (second, on the right side of the sheet)
          if (backRight > 0 && backRight <= actualTotalPages) {
            const sourcePageIndex = backRight - startPadding - 1;
            if (sourcePageIndex >= 0 && sourcePageIndex < sourcePages) {
              const embedBackRight = await finalDoc.embedPage(sourcePdfDoc.getPage(sourcePageIndex));
              await backPage.drawPage(embedBackRight, {
                x: width / 2,
                y: 0,
                width: width / 2,
                height: height,
              });
            }
          }
          // Draw back right page number
          if (showPageNumbers) {
            backPage.drawText(backRight.toString(), {
              x: 3*width/4,  // Position on right side
              y: height/2,
              size: 60,
              font: helveticaFont,
              color: rgb(1, 0, 0),
              opacity: 0.5,
            });
          }

          // Add guides to back page as well
          if (showHoleGuides || showFoldLine) {
            addHoleGuides(backPage);
          }
        }
      }

      // Instead of immediately downloading, store the blob
      const pdfBytes = await finalDoc.save();
      const originalName = file.name.replace('.pdf', '');
      const outputName = `${originalName}-booklet.pdf`;
      
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      setProcessedPdfBlob(blob);
      setProcessedFilename(outputName);

    } catch (err) {
      setError('Error processing PDF: ' + err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (sourcePageCount) {
      // Add padding to the total page count
      const paddedPageCount = sourcePageCount + startPadding + endPadding;
      
      // Calculate signature layout
      const signatures = calculateSignaturePageOrder(
        paddedPageCount, 
        sheetsPerSignature, 
        fillLastSignature
      );
      setCurrentSignatures(signatures);
    }
  }, [sourcePageCount, sheetsPerSignature, fillLastSignature, startPadding, endPadding]);

  const handleTutorialComplete = () => {
    if (showTutorial) {
      setShowTutorial(false);
      localStorage.setItem('tutorialComplete', 'true');
      if (sourcePageCount !== null && !localStorage.getItem('postUploadTutorialComplete')) {
        setShowPostUploadTutorial(true);
      }
    }
    if (showPostUploadTutorial) {
      setShowPostUploadTutorial(false);
      localStorage.setItem('postUploadTutorialComplete', 'true');
    }
  };

  const handleFileInput = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Clear existing states first
    setSourcePageCount(null);
    setCurrentSignatures(null);
    setPdfFile(null);
    setError(null);

    if (file.type === 'application/pdf') {
      setPdfFile(file);
      await loadPdfDetails(file);
    } else {
      setError('Please select a valid PDF file');
    }
  };

  const handleDownload = () => {
    if (processedPdfBlob) {
      const url = URL.createObjectURL(processedPdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = processedFilename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  const handleClosePanel = () => {
    setProcessedPdfBlob(null);
    setProcessedFilename('');
    setIsProcessing(false);
  };

  const togglePanel = (panelName) => {
    if (!showTutorial) {
      setPanelStates(prev => ({
        ...prev,
        [panelName]: !prev[panelName]
      }));
    }
  };

  const debugSectionJsx = (

    <div className="debug-controls">
      <label title="Show page numbers on the output diagram to help verify the layout">
        <input
          type="checkbox"
          checked={showPageNumbers}
          onChange={(e) => setShowPageNumbers(e.target.checked)}
          title="Useful for checking page ordering in the final booklet"
        />
        Show debug page numbers
      </label>
    </div>

  );

  // Add this effect to handle panel states during tutorial
  useEffect(() => {
    if (showTutorial) {
      setPanelStates(prev => ({
        ...prev,
        pageLayout: true,
        paperSize: true,
        bookbinding: true,
        debug: true
      }));
    }
  }, [showTutorial]);

  return (
    <div className="App">
      <VersionIndicator />
      <header className="App-header">
        <h1>EnBooken - PDF to Booklet Converter</h1>
        <p className="definition">
          <span className="word">en·book·en</span> <span className="pronunciation">/enˈbo͝okən/</span> <span className="type">verb</span>
          <br />
          <span className="meaning">to transform any PDF into a properly arranged, printable book format for bookbinding</span>
        </p>
        <meta name="description" content="Free online tool to convert PDFs into printable booklet format. Create signatures for bookbinding, add fold guides, and customize hole punch marks. No upload required - process PDFs privately in your browser." />
      </header>
      
      <div 
          className={`interactive-diagram ${isDragging ? 'drag-active' : ''}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => document.querySelector('input[type="file"]').click()}
        >
          {pdfFile && (
            <div className="pdf-title">
              {pdfFile.name}
            </div>
          )}
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileInput}
            style={{ display: 'none' }}
          />
          <PageLayoutDiagram
            sourcePages={sourcePageCount}
            signatures={currentSignatures}
            sheetsPerSignature={sheetsPerSignature}
            pageOrientation={pageOrientation}
            showHoleGuides={showHoleGuides}
            showFoldLine={showFoldLine}
            startPadding={startPadding}
            endPadding={endPadding}
            showPageNumbers={showPageNumbers}
            pdfFile={pdfFile}
          />
        </div>
      
      <div className="input-section">
        <div className="settings-grid">
          <div className="panel-header" onClick={() => togglePanel('pageLayout')}>
            <h3>Page Layout</h3>
            <span className={`collapse-arrow ${panelStates.pageLayout ? 'expanded' : ''}`}>▼</span>
          </div>
          {panelStates.pageLayout && (
            <div className="panel-content">
              <div className="page-layout-controls">
                <div className="input-group horizontal">
                  <label title="Number of sheets of paper that will be folded together to create each section of your book">
                    Sheets/signature:
                    <input
                      type="number"
                      min="1"
                      value={sheetsPerSignature}
                      onChange={(e) => setSheetsPerSignature(parseInt(e.target.value))}
                      title="Higher numbers create thicker sections but may be harder to fold neatly"
                    />
                  </label>
                  <label title="Add blank pages to ensure the last signature has the same number of sheets as other signatures">
                    <input
                      type="checkbox"
                      checked={fillLastSignature}
                      onChange={(e) => setFillLastSignature(e.target.checked)}
                      title="When checked, ensures consistent thickness across all sections"
                    />
                    Fill last signature
                  </label>
                </div>
                
                <div className="padding-controls">
                  <label title="Number of blank pages to add at the start of the document">
                    Start padding:
                    <input
                      type="number"
                      min="0"
                      value={startPadding}
                      onChange={(e) => setStartPadding(Math.max(0, parseInt(e.target.value) || 0))}
                      title="Useful for adding blank pages at the beginning of your book"
                    />
                  </label>
                  <label title="Number of blank pages to add at the end of the document">
                    End padding:
                    <input
                      type="number"
                      min="0"
                      value={endPadding}
                      onChange={(e) => setEndPadding(Math.max(0, parseInt(e.target.value) || 0))}
                      title="Useful for adding blank pages at the end of your book"
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="settings-grid">
          <div className="panel-header" onClick={() => togglePanel('paperSize')}>
            <h3>Paper Size</h3>
            <span className={`collapse-arrow ${panelStates.paperSize ? 'expanded' : ''}`}>▼</span>
          </div>
          {panelStates.paperSize && (
            <div className="panel-content">
              <div className="input-group">

                <div className="paper-size-container">
                  <select
                    value={outputPaperSize}
                    onChange={(e) => setOutputPaperSize(e.target.value)}
                    title="Select the paper size you'll print on"
                  >
                    {Object.entries(PAPER_SIZES).map(([key, { name }]) => (
                      <option key={key} value={key}>
                        {name}
                      </option>
                    ))}
                  </select>
                  {outputPaperSize === 'CUSTOM' && (
                    <div className="custom-paper-size">
                      <div className="input-group">
                        <label title="Width of paper">
                          Width:
                          <input
                            type="number"
                            min="1"
                            max={useMillimeters ? 2000 : 100}
                            step={useMillimeters ? 1 : 0.1}
                            value={useMillimeters ? Math.round(customPaperWidth * 25.4) : customPaperWidth}
                            onChange={(e) => setCustomPaperWidth(useMillimeters ? 
                              parseFloat(e.target.value) / 25.4 : 
                              parseFloat(e.target.value)
                            )}
                          />
                        </label>
                      </div>
                      <div className="input-group">
                        <label title="Height of paper">
                          Height:
                          <input
                            type="number"
                            min="1"
                            max={useMillimeters ? 2000 : 100}
                            step={useMillimeters ? 1 : 0.1}
                            value={useMillimeters ? Math.round(customPaperHeight * 25.4) : customPaperHeight}
                            onChange={(e) => setCustomPaperHeight(useMillimeters ? 
                              parseFloat(e.target.value) / 25.4 : 
                              parseFloat(e.target.value)
                            )}
                          />
                        </label>
                      </div>
                      <div className="units-toggle">
                        <label>
                          <input
                            type="checkbox"
                            checked={useMillimeters}
                            onChange={(e) => setUseMillimeters(e.target.checked)}
                          />
                          Use mm
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="bookbinding-guides">
          <div className="panel-header" onClick={() => togglePanel('bookbinding')}>
            <h3>Bookbinding Guides</h3>
            <span className={`collapse-arrow ${panelStates.bookbinding ? 'expanded' : ''}`}>▼</span>
          </div>
          {panelStates.bookbinding && (
            <div className="panel-content">
              <div className="guide-options">
                <div className="input-group hole-guides-group">
                  <label title="Add dots to mark where holes should be punched for binding">
                    <input
                      type="checkbox"
                      checked={showHoleGuides}
                      onChange={(e) => setShowHoleGuides(e.target.checked)}
                      title="Shows where to punch holes for binding your book"
                    />
                    Show hole guides
                  </label>
                  <label title="Adjust the number of holes for your binding style" className="hole-count-label">
                    Number of holes:
                    <input
                      type="number"
                      min="2"
                      max="20"
                      value={numberOfHoles}
                      onChange={(e) => setNumberOfHoles(parseInt(e.target.value))}
                      disabled={!showHoleGuides}
                      title="More holes can provide stronger binding but take longer to sew"
                    />
                  </label>
                </div>
                <div className="input-group">
                  <label title="Add a dotted line to show where to fold each sheet">
                    <input
                      type="checkbox"
                      checked={showFoldLine}
                      onChange={(e) => setShowFoldLine(e.target.checked)}
                      title="Shows where to fold each sheet of paper"
                    />
                    Show fold line
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>

        

        <button
          onClick={() => pdfFile && processPDF(pdfFile)}
          disabled={!pdfFile || isProcessing}
        >
          {isProcessing ? 'Processing...' : 'Create Booklet'}
        </button>

        <p className="privacy-notice standalone">
          Privacy Notice: This app stores your settings locally in your browser. If you choose to donate, 
          payment processing is handled securely by Stripe and no payment information is ever stored by EnBooken. 
          No other personal data is collected or transmitted to any server.
        </p>

        <div className="debug-section">
          <div className="panel-header" onClick={() => togglePanel('debug')}>
            <h3>Debug Options</h3>
            <span className={`collapse-arrow ${panelStates.debug ? 'expanded' : ''}`}>▼</span>
          </div>
          {panelStates.debug && (
            <div className="panel-content">
              {debugSectionJsx}
            </div>
          )}
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}
      
      <ProcessingPanel
        isProcessing={isProcessing}
        pdfBlob={processedPdfBlob}
        onDownload={handleDownload}
        onClose={handleClosePanel}
        filename={processedFilename}
      />
      
      {(showTutorial || showPostUploadTutorial) && (
        <Tutorial 
          onComplete={handleTutorialComplete}
          hasPdfUploaded={sourcePageCount !== null}
        />
      )}
    </div>
  );
}

export default App;