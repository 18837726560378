import React from 'react';
import PropTypes from 'prop-types';

const PlaceholderDiagram = ({ isLoading = false }) => (
  <div className="page-layout-diagram placeholder">
    <div className="placeholder-content">
      {isLoading ? (
        <p>Loading document...</p>
      ) : (
        <>
          <p>Upload a PDF to view the page layout diagram</p>
          <div className="placeholder-illustration">
            <div className="placeholder-page"></div>
            <div className="placeholder-arrow">→</div>
            <div className="placeholder-booklet">
              <div className="placeholder-sheet"></div>
              <div className="placeholder-sheet"></div>
            </div>
          </div>
          <p className="privacy-notice">Your PDF is processed entirely in your browser and is never uploaded to any server.</p>
        </>
      )}
    </div>
  </div>
);

PlaceholderDiagram.propTypes = {
  isLoading: PropTypes.bool
};

export default PlaceholderDiagram; 