import React from 'react';
import PropTypes from 'prop-types';

const PageGuides = ({ showFoldLine, showHoleGuides }) => (
  <div className="page-guides">
    {showFoldLine && <div className="fold-line" />}
    {showHoleGuides && (
      <div className="hole-guides">
        {[...Array(3)].map((_, index) => (
          <div key={`hole-${index}`} className="hole" />
        ))}
      </div>
    )}
  </div>
);

PageGuides.propTypes = {
  showFoldLine: PropTypes.bool,
  showHoleGuides: PropTypes.bool
};

export default PageGuides; 