import React from 'react';
import PropTypes from 'prop-types';
import PageGuides from './PageGuides';

/**
 * PageCard Component
 * 
 * Renders an individual page visualization in the layout diagram.
 * Each card can represent either an actual page or a blank page,
 * and can display page numbers, fold guides, and hole punch guides.
 *
 * @component
 */
const PageCard = ({ 
  pageNumbers,     // Array of page numbers to display (can include blanks)
  isBlank = false,  // Default parameter instead of defaultProps
  orientation = 'portrait',
  showDebugNumbers = false,
  showGuides = true,
  showHoleGuides = true,
  showFoldLine = true
}) => (
  <div className={`
    page-card 
    ${orientation} 
    ${isBlank ? 'blank-page' : ''} 
    ${showDebugNumbers ? 'debug-numbers' : ''}
  `}>
    {/* Display page numbers in their respective positions */}
    <div className="page-numbers">
      {pageNumbers.map((num, idx) => (
        <span key={idx}>{num > 0 ? num : ''}</span>
      ))}
    </div>
    {/* Render guides if enabled */}
    {showGuides && <PageGuides 
      showFoldLine={showFoldLine} 
      showHoleGuides={showHoleGuides} 
    />}
  </div>
);

PageCard.propTypes = {
  pageNumbers: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])).isRequired,
  isBlank: PropTypes.bool,
  orientation: PropTypes.string,
  showDebugNumbers: PropTypes.bool,
  showGuides: PropTypes.bool,
  showHoleGuides: PropTypes.bool,
  showFoldLine: PropTypes.bool
};

export default PageCard; 