import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderDiagram from './PlaceholderDiagram';
import PageCard from './PageCard';
import SheetPair from './SheetPair';
import { getAdjustedPageNumber } from '../utils/pageCalculations';

/**
 * SVG component for displaying ellipsis between page groups
 * Indicates hidden pages/signatures in the diagram
 * 
 * @component
 * @private
 */
const EllipsisIcon = () => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="currentColor"
    className="ellipsis-icon"
    aria-label="Hidden pages indicator"
  >
    <circle cx="5" cy="12" r="2" />
    <circle cx="12" cy="12" r="2" />
    <circle cx="19" cy="12" r="2" />
  </svg>
);

/**
 * PageLayoutDiagram Component
 * 
 * Provides a visual representation of how pages will be arranged in the final booklet.
 * Shows both the input PDF layout and the output signature arrangement.
 * Handles large documents by showing first/last pages with ellipsis for hidden pages.
 *
 * @component
 */
const PageLayoutDiagram = ({ 
  sourcePages, 
  signatures, 
  pageOrientation = 'portrait',
  showHoleGuides = false,
  showFoldLine = false,
  startPadding = 0,
  endPadding = 0,
  showPageNumbers = false,
  pdfFile = null
}) => {
  // Early return for loading/empty state
  if (!sourcePages || !signatures) {
    return <PlaceholderDiagram isLoading={pdfFile !== null} />;
  }

  // Generate complete page array including padding pages
  const allPages = [
    ...Array(startPadding).fill(''),
    ...Array.from({ length: sourcePages }, (_, i) => i + 1),
    ...Array(endPadding).fill('')
  ];

  // Configuration for page display
  const pagesPerRow = 8;

  /**
   * Calculate which pages to display with ellipsis for long documents
   * Returns an array of page rows to display
   * @private
   */
  const calculateDisplayPages = () => {
    if (allPages.length <= pagesPerRow) {
      return {
        pages: [allPages],
        hasHidden: false
      };
    }

    const firstRow = allPages.slice(0, pagesPerRow);
    const remainingPages = allPages.slice(pagesPerRow);
    const lastRow = remainingPages.slice(-Math.min(remainingPages.length, pagesPerRow));
    
    return {
      pages: [firstRow, lastRow],
      hasHidden: remainingPages.length > lastRow.length
    };
  };

  /**
   * Calculate which signatures to display with ellipsis
   * Returns filtered signature array for display
   * @private
   */
  const calculateDisplaySignatures = () => {
    if (signatures.length <= 2) {
      return {
        sigs: signatures,
        hasHidden: false
      };
    }

    return {
      sigs: [signatures[0], signatures[signatures.length - 1]],
      hasHidden: true
    };
  };

  const { pages: displayPages, hasHidden: hasHiddenPages } = calculateDisplayPages();
  const { sigs: displaySignatures, hasHidden: hasHiddenSignatures } = calculateDisplaySignatures();

  return (
    <div className="page-layout-diagram">
      {/* Input PDF Layout Section */}
      <div className="diagram-section">
        <h3>Input PDF</h3>
        {displayPages.map((row, rowIndex) => (
          <React.Fragment key={`row-container-${rowIndex}`}>
            <div className="page-row">
              {row.map((num, idx) => (
                <PageCard 
                  key={`source-${rowIndex}-${idx}`}
                  pageNumbers={[num]}
                  isBlank={num === ''}
                  orientation={pageOrientation}
                  showDebugNumbers={false}
                  showGuides={false}
                />
              ))}
            </div>
            {rowIndex === 0 && displayPages.length > 1 && hasHiddenPages && (
              <div className="hidden-pages-indicator">
                <EllipsisIcon />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      
      {/* Output Signature Layout Section */}
      <div className="diagram-section">
        <h3>Output PDF</h3>
        {displaySignatures.map((signature, sigIndex) => (
          <React.Fragment key={`sig-container-${sigIndex}`}>
            <div className="page-row">
              {signature.map((sheet, sheetIndex) => {
                const [[frontRight, frontLeft], [backLeft, backRight]] = sheet;
                return (
                  <SheetPair
                    key={`sig-${sigIndex}-sheet-${sheetIndex}`}
                    frontPages={[
                      getAdjustedPageNumber(frontRight, startPadding, sourcePages),
                      getAdjustedPageNumber(frontLeft, startPadding, sourcePages)
                    ]}
                    backPages={[
                      getAdjustedPageNumber(backLeft, startPadding, sourcePages),
                      getAdjustedPageNumber(backRight, startPadding, sourcePages)
                    ]}
                    showPageNumbers={showPageNumbers}
                    showHoleGuides={showHoleGuides}
                    showFoldLine={showFoldLine}
                  />
                );
              })}
            </div>
            {sigIndex === 0 && hasHiddenSignatures && (
              <div className="hidden-pages-indicator">
                <EllipsisIcon />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

PageLayoutDiagram.propTypes = {
  sourcePages: PropTypes.number,      // Total number of pages in source PDF
  signatures: PropTypes.arrayOf(      // Array of signature arrangements
    PropTypes.arrayOf(                // Each signature contains sheets
      PropTypes.arrayOf(              // Each sheet has front/back arrays
        PropTypes.arrayOf(            // Each side has left/right page numbers
          PropTypes.number
        )
      )
    )
  ),
  pageOrientation: PropTypes.oneOf(['portrait', 'landscape']),
  showHoleGuides: PropTypes.bool,     // Show binding hole guides
  showFoldLine: PropTypes.bool,       // Show fold line guides
  startPadding: PropTypes.number,     // Number of blank pages at start
  endPadding: PropTypes.number,       // Number of blank pages at end
  showPageNumbers: PropTypes.bool,    // Show debug page numbers
  pdfFile: PropTypes.object           // Uploaded PDF file object
};

export default PageLayoutDiagram; 