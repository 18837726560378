import React, { useState } from 'react';

const DonationPanel = () => {
  const amounts = [
    { amount: 3, link: 'https://buy.stripe.com/9AQ4hs5mjef99Ta002' },
    { amount: 5, link: 'https://buy.stripe.com/5kA7tE5mj9YT1mE5kl' },
    { amount: 10, link: 'https://buy.stripe.com/9AQ9BM8yv0ojaXefYY' }
  ];

  const handleDonation = (link) => {
    // Open donation link in new tab
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="donation-panel">
      <p>Support EnBooken's Development</p>
      <div className="donation-amounts">
        {amounts.map(({ amount, link }) => (
          <button
            key={amount}
            className="donation-button"
            onClick={() => handleDonation(link)}
          >
            ${amount}
          </button>
        ))}
      </div>
      <p className="donation-note">Your support helps keep EnBooken free and ad-free!</p>
    </div>
  );
};

const ProcessingPanel = ({ isProcessing, pdfBlob, onDownload, onClose, filename }) => {
  const [fairUseConfirmed, setFairUseConfirmed] = useState(false);

  if (!isProcessing && !pdfBlob) return null;

  return (
    <div className="processing-panel-overlay">
      <div className="processing-panel">
        <button className="close-button" onClick={onClose}>&times;</button>
        {isProcessing ? (
          <div className="processing-content">
            <div className="spinner" />
            <p>Processing your PDF...</p>
          </div>
        ) : (
          <div className="download-content">
            <h3>Your booklet is ready!</h3>
            
            <DonationPanel />
            
            <div className="fair-use-section">
              <label className="fair-use-label">
                <input
                  type="checkbox"
                  checked={fairUseConfirmed}
                  onChange={(e) => setFairUseConfirmed(e.target.checked)}
                />
                I confirm that this PDF will be used in accordance with fair use principles
                and applicable copyright laws.
              </label>
            </div>
            
            <p className="filename">{filename}</p>
            <button
              className="download-button"
              onClick={onDownload}
              disabled={!fairUseConfirmed}
            >
              Download PDF
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProcessingPanel; 