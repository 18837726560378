import React, { useState } from 'react';

const VERSION = '0.1.5';
const CHANGELOG = [
  {
    version: '0.1.5',
    date: '2024-11-26',
    changes: [
      'Added support for custom paper sizes',
      'Removed file size limit',
      'UI improvements',
      'Added donation request to download page',
    ]
  },
  {
    version: '0.1.4',
    date: '2024-11-24',
    changes: [
      'Added A10 paper size',
      'Fixed several UI formatting issues',
      'Better SEO tagging'
    ]
  },
  {
    version: '0.1.3',
    date: '2024-11-23',
    changes: [
      'Better mobile formatting',
      'Reduced visual clutter for large PDFs',
      'Changed logo from the default react icon',
      'Settings are now cached locally',
      'Added privacy statement'
    ]
  }
];

const VersionIndicator = () => {
  const [showChangelog, setShowChangelog] = useState(false);

  return (
    <div className="version-indicator">
      <span 
        className="version-number"
        onMouseEnter={() => setShowChangelog(true)}
        onMouseLeave={() => setShowChangelog(false)}
      >
        v{VERSION}
      </span>
      {showChangelog && (
        <div className="changelog-popup">
          {CHANGELOG.map(({ version, date, changes }) => (
            <div key={version} className="changelog-entry">
              <div className="changelog-header">
                <span className="changelog-version">v{version}</span>
                <span className="changelog-date">{date}</span>
              </div>
              <ul className="changelog-changes">
                {changes.map((change, index) => (
                  <li key={index}>{change}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VersionIndicator; 