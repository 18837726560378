import React, { useState, useEffect, useCallback } from 'react';

const initialTutorialSteps = [
  {
    target: '.interactive-diagram',
    content: 'Start by uploading a PDF',
    position: 'bottom'
  },
  {
    target: '.settings-grid:nth-child(1)',
    content: 'Customize how many pages are grouped together in each signature',
    position: 'bottom'
  },
  {
    target: '.settings-grid:nth-child(2)',
    content: 'Choose your output paper size. This should match the paper you\'ll print on',
    position: 'bottom'
  },
  {
    target: '.bookbinding-guides',
    content: 'Enable guides to help with folding and binding your booklet',
    position: 'bottom'
  },
  {
    target: '.debug-section',
    content: 'Enable page numbers to preview the layout of your booklet',
    position: 'bottom'
  }
];

const postUploadSteps = [
  {
    target: '.diagram-section:first-child',
    content: 'This shows your input PDF pages in order. Any padding pages will be shown as blank.',
    position: 'bottom'
  },
  {
    target: '.diagram-section:last-child',
    content: 'This shows how your pages will be arranged in the final booklet. Each row represents a signature that will be folded together.',
    position: 'bottom'
  }
];

const Tutorial = ({ onComplete, hasPdfUploaded }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [targetRect, setTargetRect] = useState(null);
  const [tutorialMode, setTutorialMode] = useState('initial');
  const [isEntering, setIsEntering] = useState(true);

  const currentTutorialSteps = tutorialMode === 'initial' 
    ? initialTutorialSteps 
    : postUploadSteps;

  const completeTutorial = useCallback(() => {
    setIsVisible(false);
    if (tutorialMode === 'initial') {
      localStorage.setItem('tutorialComplete', 'true');
    } else {
      localStorage.setItem('postUploadTutorialComplete', 'true');
    }
    if (onComplete) onComplete();
  }, [tutorialMode, onComplete]);

  useEffect(() => {
    if (tutorialMode === 'initial') {
      const timer = setTimeout(() => {
        setIsVisible(true);
        setTimeout(() => {
          setIsEntering(false);
        }, 50);
      }, 0);

      return () => clearTimeout(timer);
    }
  }, [tutorialMode]);

  useEffect(() => {
    if (hasPdfUploaded && !localStorage.getItem('postUploadTutorialComplete')) {
      setTutorialMode('post-upload');
      setCurrentStep(0);
      setIsEntering(true);
      setIsVisible(false);

      const timer = setTimeout(() => {
        setIsVisible(true);
        setTimeout(() => {
          setIsEntering(false);
        }, 50);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [hasPdfUploaded]);

  useEffect(() => {
    const handleKeyPress = () => {
      if (currentStep < currentTutorialSteps.length - 1) {
        setCurrentStep(prev => prev + 1);
      } else {
        completeTutorial();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentStep, currentTutorialSteps.length, completeTutorial]);

  useEffect(() => {
    if (isVisible) {
      const updateTargetRect = () => {
        const currentTarget = document.querySelector(currentTutorialSteps[currentStep].target);
        if (currentTarget) {
          const rect = currentTarget.getBoundingClientRect();
          
          setTargetRect({
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height,
            bottom: rect.bottom
          });
        }
      };

      updateTargetRect();
      
      let ticking = false;
      const scrollHandler = () => {
        if (!ticking) {
          window.requestAnimationFrame(() => {
            updateTargetRect();
            ticking = false;
          });
          ticking = true;
        }
      };

      window.addEventListener('scroll', scrollHandler, { passive: true });
      window.addEventListener('resize', updateTargetRect);
      
      return () => {
        window.removeEventListener('scroll', scrollHandler);
        window.removeEventListener('resize', updateTargetRect);
      };
    }
  }, [currentStep, isVisible, currentTutorialSteps]);

  const changeStep = (newStep) => {
    setTimeout(() => {
      setCurrentStep(newStep);
    }, 300);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      if (currentStep < currentTutorialSteps.length - 1) {
        changeStep(currentStep + 1);
      } else {
        completeTutorial();
      }
    }
  };

  const handleProgressDotClick = (index) => {
    if (index < currentStep) {
      changeStep(index);
    }
  };

  if (!targetRect) return null;

  return (
    <div 
      className={`tutorial-overlay ${!isEntering ? 'visible' : ''}`}
      onClick={handleOverlayClick}
    >
      <div className="tutorial-skip-container">
        <button 
          onClick={(e) => {
            e.stopPropagation();
            completeTutorial();
          }} 
          className="skip-tutorial"
        >
          Skip Tutorial
        </button>
      </div>
      <div className="tutorial-highlight"
        style={{
          position: 'fixed',
          top: targetRect.top - 12,
          left: targetRect.left - 12,
          width: targetRect.width + 24,
          height: targetRect.height + 24,
        }}
      />
      <div 
        className="tutorial-content"
        onClick={(e) => e.stopPropagation()}
        style={{
          position: 'fixed',
          top: (() => {
            const position = currentTutorialSteps[currentStep].position;
            const padding = 24;
            
            switch (position) {
              case 'bottom':
                return targetRect.bottom + padding;
              case 'bottom-above':
                return targetRect.top - 160;
              case 'top':
                return targetRect.top - 80;
              default:
                return targetRect.bottom + padding;
            }
          })(),
          left: targetRect.left + (targetRect.width / 2),
          opacity: targetRect ? 1 : 0,
        }}
      >
        <p>{currentTutorialSteps[currentStep].content}</p>
        <div className="tutorial-controls">
          <div className="tutorial-progress">
            {currentTutorialSteps.map((_, index) => (
              <div 
                key={index} 
                className={`progress-dot ${index === currentStep ? 'active' : ''} ${index < currentStep ? 'clickable' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleProgressDotClick(index);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial; 