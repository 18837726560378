import React from 'react';
import PropTypes from 'prop-types';
import PageCard from './PageCard';

const SheetPair = ({ 
  frontPages, 
  backPages, 
  showPageNumbers, 
  showHoleGuides, 
  showFoldLine 
}) => (
  <div className="page-pair">
    <PageCard 
      pageNumbers={frontPages}
      isBlank={frontPages.some(num => num === 0)}
      orientation="landscape"
      showDebugNumbers={showPageNumbers}
      showGuides={showHoleGuides || showFoldLine}
      showHoleGuides={showHoleGuides}
      showFoldLine={showFoldLine}
    />
    <PageCard 
      pageNumbers={backPages}
      isBlank={backPages.some(num => num === 0)}
      orientation="landscape"
      showDebugNumbers={showPageNumbers}
      showGuides={showHoleGuides || showFoldLine}
      showHoleGuides={showHoleGuides}
      showFoldLine={showFoldLine}
    />
  </div>
);

SheetPair.propTypes = {
  frontPages: PropTypes.arrayOf(PropTypes.number).isRequired,
  backPages: PropTypes.arrayOf(PropTypes.number).isRequired,
  showPageNumbers: PropTypes.bool,
  showHoleGuides: PropTypes.bool,
  showFoldLine: PropTypes.bool
};

export default SheetPair; 